
@media (min-width: 1920px) {
    /* .fluid-header {
      padding: 10px 15px;
    }
    .header-fixed.sticky.fluid-header { 
      padding: 10px 15px;
    } */
  
  
    .trade_body .fluid-header {
      padding: 4px 0;
      }
  
  
  
  }
  @media (max-width: 1919px) {
    /* .fluid-header {
      padding: 10px 15px;
    }
    .header-fixed.sticky.fluid-header { 
      padding: 10px 15px;
    } */
  }
  @media (max-width: 1499px) {
    /* .fluid-header {
      padding: 10px 15px;
    }
    .header-fixed.sticky.fluid-header { 
      padding: 10px 15px;
    } */
    .trade_body .fluid-header {
          padding: 5px 15px;
      }
  
  
  }
   
  
  
  
  .trade-wrapper {
    padding-top: 0 !important;
}
  
   
  .spot-container-main {
      display: flex;
      flex-direction: column;
      /*padding: 0 15px;*/
      flex: 1;
  }
  .spot-container-main .top {
      display: flex;
  }
  .trade_row{
      margin: 0 0;
  }
  .trade_col {
      padding: 0 5px;
      width: 100%;
  }
  
  
  .spot .title{
      /* color: #ffffff; */
  }
  
  
  .spot-container-main .top-left {
      flex-grow: 1;
  }
  
  .spot-container-main .top-right {
      width: 380px;
  }
  
  
  
  
  .container-chart-briefing {
      height: 80px;
      position: relative;
      justify-content: flex-start;
      width: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
  }
  
  .trade_card {
    background: #1e1e1e;
    border: 0;
    border-radius: 15px;
    overflow: hidden;
}
 
  
.theme-light .trade_card {
    background: #fff;
    border: 1px solid rgb(255 255 255);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}
  
  .container-chart-briefing-info {
      font-size: 12px;
      text-align: left;
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      justify-content: space-between;
      align-items:center;
  }
  
  dd, dl, dt {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    line-height: 1.5;
}
  
  .container-chart-briefing h2 {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      padding-right: 20px;
      cursor: pointer;
      font-weight: 700;
      color: #ffffff;
  }
  
  .container-chart-briefing-info dd.big, .container-chart-briefing-info dt.big {
      font-size: 18px;
      font-weight: 700;
  }
  
  .container-chart-briefing-info dd.green, .container-chart-briefing-info dt.green {
      color: #0bba74!important;
  }
  
  .container-chart-briefing .displayName>span {
      padding-right: 10px;
  }
  
 
  .trade-wrapper .btn {
    justify-content: center;
    padding: 7px 35px;
}
  
  
  
  
  .tv-chart {
      width: 100%;
      height: 507px;
  }
  
  
  /*order book*/
  
  .card_title {
      font-size: 16px;
      font-weight: 600;
      /*border-bottom: 1px solid rgb(64 64 64);*/
      text-align: center;
      padding-bottom: 1rem;
      color: currentColor;
  }
  
  .theme-light .card_title {
      /*border-color: rgb(0 0 0 / 5%);*/
  }
  
  .trade_tabs {
      padding: 10px; 
  }
  
  .trade_card_body.single-widget ul li a {
      font-size: 14PX;
  }
  
  .price_card_head {
    display: flex; 
    overflow: hidden;
    position: relative;
    font-size: 12px;
    /* line-height: 12px;  */
    opacity: 0.7;
    border-bottom: 1px solid #ffffff2b;
    height: 30px;
    line-height: 30px;
}
  
  .price_card_body::-webkit-scrollbar,
  .scroll_y::-webkit-scrollbar, .scroll_x::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
  }
  .price_card_body::-webkit-scrollbar-thumb,
  .scroll_y::-webkit-scrollbar-thumb, .scroll_x::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: rgb(106 106 106 / 21%);
      background-color: transparent;
  }
  .price_card_body:hover::-webkit-scrollbar-thumb,
  .scroll_y:hover::-webkit-scrollbar-thumb, .scroll_x::-webkit-scrollbar-thumb { 
      background: rgb(106 106 106 / 21%); 
  }
 .scroll_x::-webkit-scrollbar {
      height: 3px;
      background-color: transparent;
  }
.scroll_x::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: rgb(106 106 106 / 21%);
  }


  .theme-light .price_card_body {
      /*border: 1px solid rgb(0 0 0 / 5%);*/
  }
  .price_item_value {
      /* padding-bottom: 10px;
      border-bottom: 1px solid #ffffff14;
      display: flex;
      position: relative;
      z-index: 1;
      width: 100%;
      padding: 4px 0; */


      display: flex;
        height: 20px;
        line-height: 20px;
        /* overflow: hidden; */
        position: relative;

  }
  .theme-light .price_item_value {
      /* border-bottom: 1px solid rgb(0 0 0 / 5%); */
  }
  .price_item_value span {
      display: block;
      /*color: #ffffff;*/
      font-size: 12px;
  }
  .price_item_value span:first-child, .price_card_head > div:first-child {
        display: block;
        width: 34%;
        padding-left: 10px;
        text-align: left;
    }
  .price_item_value span:nth-child(2),
  .price_card_head > div:nth-child(2) {
      display: block;
      width: 33%;
      text-align: right;
  }
  .price_item_value span:last-child, .price_card_head > div:last-child {
      display: block;
      width: 33%;
      text-align: right;
      padding-right: 10px;
      font-weight: 700;
  }
  
  
  .price_item_value:last-child{
      border: 0;
  }
  .live_ppc {
      font-weight: 500;
      padding: 1rem 0;
      line-height: 1;
  }
    .price_card_body.price_card_body_full, .scroll_y {
        height: 362.8px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .scroll_y_coins {
        height: 339px;
    }
    .scroll_y_mt {
        height: 273px;
    }
    .scroll_y_full {
        height: 747px;
    }

  /* .buy_sell_row_price .scroll_y {
    max-height: 349px;
} */
  .spot-container-right-right {
      flex: 0 0 300px;
      max-width: 300px;
  }

.cursor-pointer{
    cursor: pointer;
}

  .scroll_y_reverse{
    display: flex;
    flex-direction: column-reverse;
  }







 

  .order-4 { 
      margin: 0;
  }
  
  .buy_sell_cards ul.nav.custom-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    box-shadow: none !important;
    background: transparent !important;
    width: 100%;
    border: 0;
}
  
  .buy_sell_cards ul.nav.custom-tabs.nav_order li {
      /* max-width: fit-content; */
      flex: 1 1 auto;
      text-align: center;
  }
  
  
  .buy_sell_cards ul.nav.custom-tabs li a {
    /* box-shadow: rgb(106 106 106 / 21%) 0px 0px 0px 1px; */
    display: block;
    text-align: center;
    border-radius: 6px;
    padding: 4px 6px !important;
    color: #ffffffa6;
    /* background-color: #ffffff0d; */
    font-size: 12px;
}

.buy_sell_cards ul.nav.custom-tabs li.buysell-tab a {
    padding: 7px 13px !important;
    font-size: 14px !important;
}
  
.buy_sell_cards ul.nav.custom-tabs li a.active {
    background-color: #ffdc88 !important;
    color: #181818 !important;
}
  
  
.theme-light .buy_sell_cards ul.nav.custom-tabs li a {
    color: rgb(145 145 145 / 75%);
}
  .custom-tabs li.buy-tab a:hover {
      color: rgba(255, 255, 255, 0.75);
      /*background-color: #0bba7454;*/
  }
  
  .custom-tabs li.sell-tab a:hover {
      color: rgba(255, 255, 255, 0.75);
      /*background-color: #f93f312b;*/
  }
  
  
  .custom-tabs li.buy-tab a.active,
  .custom-tabs li.buy-tab a:active {
      color: #fff !important;
      background-color: #0bba74 !important;
  }
  
  .custom-tabs li.sell-tab a.active,
  .custom-tabs li.sell-tab a:active {
      color: #fff !important;
      background-color: #f93f31 !important;
  }
  .custom-tabs li.buysell-tab a.active {
      color: #ffffff !important;
      background-color: #0bba74 !important;
  }
  
  
  .spot-deal-warp {
      margin-top: 25px;
  }
  
  
  
  
  
  
  .spot  .input-group {
      background: transparent;
      border-radius: 6px;
      overflow: hidden;
      box-shadow: rgb(106 106 106 / 21%) 0px 0px 0px 1px;
  }
  .spot .input-group > span {
      text-align: center !important;
  }
  
  .spot .input-group > span small {
      text-align: center;
      margin: 0 auto;
  }
  .input-group-text {
      /*background-color: #20202d0d;*/
      /* border: 1px solid #20202d; */
      border-radius: 0;
      color: currentColor;
      line-height: 1.2;
      min-width: 76px;
      font-weight: 500;
      border: 0;
       background: transparent; 
  }
  .input-group-text  small {
      font-size: 75%;
  }
  .spot .form-control, .spot select {
      color: currentColor;
      background-color: #2f2f39;
      border: 0; 
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      border-radius: 3px !important;
      background: #ffffff17;
      color: #ffffff;
      backdrop-filter: blur(10px);
      border: 1px solid #ffffff1c;
      padding: 7px 18px;
      min-width: 150px;
      border-radius: 6px !important;
  }
  .theme-light .spot .form-control {
      background-color: transparent;
  }
  .form-control:focus {
      box-shadow: none;
  }
  
  .btn-group.btn-group-mini {
      width: 100%;
      border-radius: 0 !important; 
      border: 0; 
      background: transparent;
      margin-left: auto !important;
      margin-right: 4px;
  }
  .btn-group.btn-group-mini * {
    font-size: 12px;
    background: transparent;
    border: 0;
    transition: 0.0s;
    padding: 0 0px;
    color: #ffffff;
    margin: 0 auto !important;
    width: -webkit-fill-available;
    text-align: center;
    display: grid;
    min-height: auto;
    box-shadow: none !important;
    height: 38px;
    margin: 0 2px!important;
    border-radius: 6px !important;
    background: transparent;
    border: 1px solid rgb(255 255 255 / 11%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    min-width: 35px;
}
  
.theme-light .btn-group.btn-group-mini * {
    background: rgb(171 171 171 / 12%);
    border: 1px solid rgb(234 239 244); 
    color: #000;
}
  
  
  .btn-check:active + .btn-outline-success:focus, .btn-check:checked + .btn-outline-success:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus, .btn-outline-success:active:focus {
      box-shadow: none !important;
  }
  .btn-group.btn-group-mini *:focus{
      background: transparent;
  }
  .data-buy .btn-group.btn-group-mini .btn-outline-success {
      
  }
  .data-sell .btn-group.btn-group-mini .btn-outline-danger {
      
  }
  .btn-dark {
      background: #ffffff26;
  }
  .btn-check+.btn:hover {
      color: #ffffff; 
  }
  
  .btn-check+.btn:hover {
      color: var(--bs-btn-color);
      background-color: var(--bs-btn-bg);
      border-color: non;
  }
   
  .data-buy .btn-group.btn-group-mini .btn-check:checked + .btn-outline-success,
  .data-buy .btn-group.btn-group-mini .btn-outline-success:hover {
      background: #0bba74;
      color:#fff;
  }
  .data-sell .btn-group.btn-group-mini .btn-check:checked + .btn-outline-danger,
  .data-sell .btn-group.btn-group-mini .btn-outline-danger:hover {
      background: #f93f31;
      color: #fff;
  }
  .data-sell .btn-group.btn-group-mini .btn-check:checked + .btn-outline-success{ 
      border-color: #0bba74 !important;
  }
  .data-sell .btn-group.btn-group-mini .btn-check:checked + .btn-outline-danger{ 
      border-color: var(--bs-red) !important;
  }
  
  
  .trade-wrapper select, .trade-wrapper .nice-select {
    box-shadow: rgb(106 106 106 / 21%) 0px 0px 0px 1px;
    height: 44px;
    background: #ffffff17;
    color: #ababab;
    width: 100%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 50px;
    padding: 0 16px;
}
  .trade-wrapper select *, .trade-wrapper .nice-select * {
      /* font-size: 13px; */
     color: #ababab !important;
  background:#000;
  }
  .btn-block {
      display: block;
  }
  
  small.note {
      font-size: 74%;
      display: block;
      text-align: center;
      margin: 18px 12px 0;
      line-height: 1.2;
  }
  
  .buy_sell_cards button {
      margin: 1.5rem 0 1rem;
  }
  
  
  
  .trade_assets {
      margin-top: 1rem;
  }
  .trade_assets .product-owner {
      padding: 0.5rem 0;
  
      border-bottom: 1px solid #2f2f39;
  }
  .trade_assets .product-owner:last-child{
      border-bottom: 0;
  }
  .theme-light .trade_assets .product-owner {
      border-color: rgb(0 0 0 / 5%);
  }
  
  
  .trade_card table.table, .table tbody tr:hover     {
    background-color: transparent;
}


.form-select-sm {
    padding: 5px 6px !important;
    height: unset !important;
    width: min-content !important;
    min-width: unset !important;
}
 
.trade_card .table > :not(caption) > * > * {
    padding: 1rem .5rem;
    line-height: 1.5 !important;
    vertical-align: middle;
}
.trade_card .table > .btn {
    margin: 0;
}


  .spot-container-main .bottom {
      display: flex;
      min-height: 500px;
      /*border-top: 1px solid #191e23;*/
      flex: 1;
  }
  
  
  .bottom-left{
      width: calc(100% - 380px);
  }
  
  .bottom-right{
      width: 380px;
  }
  ul.nav.custom-tabs.order_his_tabs li {
      max-width: -webkit-fill-available;
      width: auto;
      padding: 0;
  }
  ul.nav.custom-tabs.order_his_tabs {
      justify-content: flex-start;
  }
  ul.nav.custom-tabs.order_his_tabs li a {
    padding: 4px 16px;
    height: unset !important;
}
 .ant-list-item{
    display: flex;
    height: 42px;
    padding: 5px 16px;
    cursor: pointer;
 } 
 .rankItem {
    height: 27px;
    line-height: 12px;
    width: 144px;
}
  
  
  
  
  
  .container-chart-briefing .spotList {
      width: 360px;
      min-height: 360px;
      padding-bottom: 10px;
      overflow-y: auto;
      overflow-x: hidden;
      background: #ffffff12;
      position: absolute;
      top: 98px;
      left: 6px;
      z-index: 111111;
      border-radius: 9px;
      display: none;
      box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
      padding: 0;
      backdrop-filter: blur(10px);
  }
  .show_spot{
      display: block !important;
  }
  
  .spotList .scroll_y {
      height: 357px;
  }
  
  .spot-list {
      position: absolute;
      left: 0;
      top: 0;
      width: 360px;
      min-height: 100%;
      background: #1f252a;
  }
  .spot-list-search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding: 10px;
      padding-top: 0;
  }
  
  .spot-list-search .ivu-input {
    background: rgb(255 255 255 / 1%);
    border: 1px solid rgb(255 255 255 / 6%);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0 12px;
}

.theme-light .spot-list-search .ivu-input {
    background: rgb(207 207 207 / 5%);
    border: 1px solid rgb(211 211 211 / 57%);
}
  
  
  .spot-list-search i {
  
      margin-right: 14px; 
  }
  .spot-list-search .ivu-input input {
    background: transparent;
    border: 0;
    outline: 0 !important;
    color: #ffffff;
    padding: 4px 3px;
    font-size: 14px;
    width: 100%;
}
.theme-light  .spot-list-search .ivu-input input {
    color: #000000;
}
  
  .price_card {
      padding: 10px 0;
      padding:0;
  }
  
  .price_card .price_card_head {
      padding: 0;
      padding-right: 0;
  }
  .price_card_body {
      padding: 0 10px; 
  }
  .price_card_body.scroll_y { 
      padding-right: 0;
      padding-left: 0;
  }
  .price_item_value .ri-xl {
      font-size: 116%;
  }
  
  .spot-list-search .ivu-input::placeholder{
      color: #ffffff;
  }
  
  
  
  
  .theme-light .container-chart-briefing .spotList {
      background: #ffffff;
      box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  }
  
  
  .trade-wrapper .favouriteData {
      text-align: center;
      padding: 8% 0;
      font-size: 300%;
  }
  
  .trade-wrapper .favouriteData p {
      color: #ffffff;
  }
  
  
  .scroll_y .favouriteData {
      height: 100%;
  }
  
  .mrkt_trde_tab {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      padding: 10px;
      border-top: 1px solid #ffffff14;
      border-bottom: 1px solid #ffffff14;
      margin: 10px 0;
  }
  .trade-wrapper .text-success {
      color: #0bba74 !important;
  }
  .theme-light  .mrkt_trde_tab { 
      border-top: 1px solid #00000014;
      border-bottom: 1px solid #00000014; 
  }
  
  
  
  
  .buysellform .input-group {
    padding: 0px 10px;
    display: flex;
    align-items: center;
    background: rgb(255 255 255 / 0%);
    border: 1px solid   rgb(255 255 255 / 11%);
    box-shadow: none;
}
  
.theme-light .buysellform .input-group {  
    
    background: rgb(255 255 255 / 0%);
    border-color :rgb(0 0 0 / 11%);
}


.buysellform .input-group .form-control, .buysellform .input-group .form-control:disabled {
    background: transparent !important;
    border: 0;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0 !important;
    font-size: 15px !important;
    backdrop-filter:blur(0);
}

.buysellform .input-group .input-group-text, .buysellform .input-group .input-group-text * {
    padding: 0;
    margin: 0;
    text-align: right !important;
    display: block;
}
  
  
.buy_sell_row.buy_sell_cards ul.nav.custom-tabs li a {
    border-radius: 3px;
    background: #ffffff2e;
    line-height: 1;
    padding: 4px 0;
}

  
.theme-light  .buy_sell_row.buy_sell_cards ul.nav.custom-tabs li a {
    background: #0000001f;
}

/* .buy_sell_row.buy_sell_cards ul.nav.custom-tabs li {
    max-width: fit-content;
}
.buy_sell_row.buy_sell_cards ul.nav.custom-tabs {   
    justify-content:end;
} */
    
  
  
  .buy_sell_row  h6 {
      font-size: 12px;
  }
  
  .buy_sell_row {
    border-bottom: 1px solid #ffffff1a;
    margin-bottom: 0;
    padding: 10px 10px;
}
  .theme-light .buy_sell_row {
      border-bottom: 1px solid #d2e4ff; 
  }
  
   .actv_data  p,
    .actv_data span {
      font-size: 13px;
  }
   
  
    .trade-wrapper .row {
        margin-left: -2.5px;
        margin-right: -2.5px;
    }
    .trade-wrapper .row > * {
        padding-left: 2.5px;
        padding-right: 2.5px;
    }
  
 
    .num-select {
        padding: 0 !important;
        background: transparent !important;
        border: 0 !important;
        width: 32px  !important;
        outline: 0 !important;
        box-shadow: none !important;
        min-width: min-content !important;
        text-align: right;
        height: -moz-fit-content !important;
        height: fit-content !important;
        font-size: 13px;
        line-height: 1.1;
    }
    .num-select *{
        max-width: unset !important;
        color: currentColor; 
    }
    .theme-light .num-select *{  
        color: #fff; 
    }
    .num-div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0;
        position: relative;
        background: transparent;
        margin-left: 0;
        border-radius: 0;
    }
   
    .num-div:after {
        font-family: "remixicon" !important;
        font-style: normal;
        content: "\ea4d";
        color: #fff;
        font-size: 17px;
        /* position: absolute; */
        /* right: 0; */
        /* top: 0; */
        /* height: 15px; */
        line-height: 1;
    }

    .theme-light .num-div:after{
        color: #333;
    }
    /* .theme-light .num-div {
        background: #0000001f;
    } */
  
  @media screen and (max-width: 767px) and (min-width: 320px){
    
    
  
      .container-chart-briefing .spotList {
          background: #22242c;
          /* box-shadow: rgb(0 0 0 / 58%) 0px 0 0 500px; */
          left: 0;
          top: 62px;
      }
  
  
      .container-chart-briefing  {
        height: auto;
        padding: 15px 15px 0;
      }
    
    .spot-container-main .top {
        flex-direction: column;
    }
    .spot-container {
        flex-direction: column;
    }
    .wm-50 {
        flex: 0 0 auto;
        margin-bottom: 17px;
        width: 50%;
    }
    .container-chart-briefing-info {
        width: 100%;
        justify-content: unset;
        flex-wrap: wrap;
    }
    .container-chart-briefing h2 {
        justify-content: flex-start;
    }
    
    .spot-container-main .bottom {
        min-height: unset;
        flex-direction: column;
    }
    .bottom-left {
        width: 100%;
        margin-bottom: 20px;
    }
    .bottom-right {
        width: 100%;
    }
    .spot-container-right-right {
        flex: auto;
        max-width: 100%;
    }
    .trade_card {
        padding: 11px 11px;
        padding: 0;
    }
    ul.nav.custom-tabs.order_his_tabs li a {
        padding: 7px 20px;
        font-size: 14px;
    }
    
    
    .trade-wrapper {
        padding-top: 0 !important;
    }
    .spot-container.container-fluid {
        padding-right: 10px;
        padding-left: 10px;
    }
    

    .select_mini {
        min-width: unset !important;
        padding: 7px 8px !important;
        font-size: 14px !important;
        height: unset !important;
    }


    }
      
    
    
    @media screen and (max-width: 991px) and (min-width: 768px){
    
    
    
      .spot-container-main .top {
          flex-direction: column;
      }
      .spot-container {
          flex-direction: column;
      }
      .wm-50 {
        flex: 0 0 auto;
        margin-bottom: 17px;
        width: 33.3333333%;
    }
      .container-chart-briefing-info {
          width: 100%;
          justify-content: unset;
          flex-wrap: wrap;
      }
      .container-chart-briefing h2 {
          justify-content: flex-start;
      }
      
      .spot-container-main .bottom {
          min-height: unset;
          flex-direction: column;
      }
      .bottom-left {
          width: 100%;
          margin-bottom: 20px;
      }
      .bottom-right, .spot-container-main .top-right {
          width: 100%;
      }
      .spot-container-right-right {
          flex: auto;
          max-width: 100%;
      }
    
      ul.nav.custom-tabs.order_his_tabs li a {
          padding: 7px 20px;
          font-size: 14px;
      }
      .container-chart-briefing {
        height: unset;
        padding-bottom: 10px;
    }
    .trade-wrapper.spot {
        max-width: 650px;
        margin-left: auto;
        margin-right: auto;
    }
      
      
      
      }
    
    
    
    
    
    
      @media screen and (max-width: 1299px) and (min-width: 992px){
    
        .spot-container{
            flex-direction: column;
        }
        .spot-container-right-right {
            flex: auto;
            max-width: 100%;
            display: flex;
        }
    
        .inner-page-banner {
            padding: 77px 0 43px;
        }
        section.pb-90.account_sec {
            padding-bottom: 0;
        }
      }
  
  
  
  
    @media (min-width: 1400px){
        .spot-container  .col-xxl-2 {
            flex: 0 0 auto;
            width: 24%;
        }
        .spot-container  .col-xxl-8 {
            flex: 0 0 auto;
            width: 52%;
        }
    }













    /* trade new css */


    .spot-container  { 
        max-width: 1520px; 
    }

    .treade_card_header {
        border-bottom: 1px solid #2b2f39;
        display: flex;
        align-items: center;
        padding-inline-start: 5px;
        flex-shrink: 0;
    }
    .theme-light .treade_card_header {
        border-bottom: 1px solid #f7f7f7; 
    }

    .card_header_title {
        height: 38px;
        line-height: 38px;
        padding: 0 10px;
        cursor: pointer;
        position: relative;
    }

    .card_header_title:before {
        position: absolute;
        content: " ";
        width: 16px;
        height: 2px;
        border-radius: 2px;
        /* background-color: #ffdc88 ; */
        background-color: #ffdc88 ;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%) scaleX(.5);
        opacity: 0;
        transition: all .16s ease-in;
    }
    .card_header_title.active {
        /* color: #ffdc88; */
        color: #ffdc88;
    }    
    .theme-light .card_header_title.active {
        color: #000000;
    }
    .card_header_title.active:before {
        opacity: 1;
        transform: translateX(-50%) scaleX(1);
    }
    .nav.custom-tabs.nav_order {
        width: auto !important;
    }
    .trade_tabs.buy_sell_cards.buy_sell_row.d-flex-between {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 24px;
        padding: 8px 10px 4px 10px;
        border: 0;
    }
    
    .buy_sell_row.buy_sell_cards ul.nav.custom-tabs li a {
        background: transparent !important;
    }
    .buy_sell_row.buy_sell_cards ul.nav.custom-tabs li a.active {
        background: #ffffff45 !important;
    }
    .theme-light .buy_sell_row.buy_sell_cards ul.nav.custom-tabs li a.active {
        background: #e9e9e9 !important;
    }
    .theme-light .price_card_head { 
        border-color:   #00000012; 
    }

    .scroll_y_hidden {
        overflow: hidden;
    }



    .headline_left__lBBPY {
        display: flex;
        align-items: center;
    }
    .headline_left__lBBPY .headline_symbolName__KfmIZ {
        white-space: nowrap;
        margin-right: 19px;
    }
    .headline_bigName__dspVW {
        display: flex;
        align-items: center;
        -webkit-margin-end: 10px;
        margin-inline-end: 10px;
    }
    .headline_bigName__dspVW, .headline_bigName__dspVW h1 {
        line-height: 20px;
        font-size: 14px;
        font-weight: 700; 
    }
    .headline_etfDisplay__P4Hdv {
        font-size: 10px;
        line-height: 14px;
        font-weight: 400; 
        display: flex;
        align-items: center;
        -webkit-margin-end: 10px;
        margin-inline-end: 10px;
        opacity: 0.7;
    }
    .headline_leftItem__7BFYq {
        margin-inline-end: 15px;
        font-size: 12px;
        line-height: 20px;
        white-space: nowrap;
        font-weight: normal;
        font-weight: 700;
    }
    .headline_latestPrice__AYXu0 .headline_title__x1csO {
        font-size: 14px;
    }

    .scroll-subtabs_scrollSubInfo__T5nZF {
        flex: 1 1;
        width: 0;
        margin-inline-start: 30px;
        position: relative;
    }
    .scroll-subtabs_tabs__Prom8 {
        flex-flow: row nowrap;
        display: flex;
        overflow: hidden;
        position: relative;
    }
    .scroll-subtabs_subMarketWrap__XVmHp {
        overflow: auto hidden;
        white-space: nowrap;
        scroll-behavior: smooth;
        scrollbar-width: none;
    }

    .headline_extendInfoWrapper__dooIS, .headline_left__lBBPY, .headline_wrapper__BKn9L {
        display: flex;
        align-items: center;
    }
    .headline_leftItem__7BFYq {
        margin-inline-end: 34px;
        font-size: 12px;
        line-height: 20px;
        white-space: nowrap;
    }
    .headline_withBorder__a6ZD2 {
        border-bottom: 1px dashed transparent;
         opacity: 0.7;
    }




/* token info */



.exchange_chartContent__Ijukg {
    height: 395px;
    overflow-y: auto;
}
.information_wrapper__AR6LL { 
    flex-flow: column nowrap;
    padding: 10px 15px; 
    height: auto;
    max-height: 100%;
    width: 100%; 
}
.information_icon__Lztnq {
    width: 40px;
    height: 40px;
    background-size: contain;
    margin-inline-end: 15px;
    border-radius: 50%;
    border: 1px solid #ffffff21;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.theme-light .information_icon__Lztnq { 
    border-color: #ffffff21; 
}
.information_title__TYI4l {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.information_name__ygI29 {
    font-size: 20px;
    font-weight: 700;
}

.information_other__OQlyX {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
}
.information_left__vgFSY, .information_right__kqyPI {
    width: 45%;
}
.information_infoItem__3_gv6 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    font-size: 14px;
}
.information_infoItem__3_gv6>div {
    white-space: nowrap;
    opacity: 0.6;
}
 
.information_bright__F_RTd {
    opacity: 1;
    font-size: 14px;
}
.information_infoLink__shSep {
    color: #ffdc88;
    -webkit-margin-start: 10px;
    margin-inline-start: 10px;
    display: inline-block;
}
.information_infoLink__shSep_row{
    opacity: 1 !important;
}

.scroll-container{
    overflow-y: auto;
    height: 175px;
}
.information_name__ygI29.information_bright__F_RTd {
    font-size: 20px;
    margin: 5px 0;
}

.tft_fee_title {
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    padding-right: 10px;
}
.tft_fee_title span {
    padding: 0 6px;
}
.tft_fee_title > i {
    font-size: 123%;
    opacity: 0.5;
}
.hot_hotTag__YZlkh.hotTag {
    font-size: 10px;
    font-weight: 700;
    background: #cf2b31;
    border-radius: 1px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 3px;
    color: #fff;
    transform-origin: left center;
}

.spot_limit.num-div {
    margin-bottom: 11px;
    margin-top: 4px;
}


.actions_balance__kTHO0 {
    margin-bottom: 5px;
    min-height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
}
.actions_primaryText__ufKT0 {
    opacity: 0.7;
    margin-right: 10px;
}
a.actions_deposit__Ydutk {
    color: #ffdc88;
    font-size: 125%;
    margin-left: 10px;
    line-height: 1;
}


.trade-wrapper .row.gx-5 {
    margin-left: -1rem;
    margin-right: -1rem;
}

.trade-wrapper .row.gx-5 > * {
    padding-left: 1rem;
    padding-right: 1rem;
}


@media (min-width:992px){
    .devider_right_md {
        border-right: 1px dashed #ffffff1f;
    }
    .theme-light .devider_right_md {
        border-right: 1px dashed #0000001f;
    }

}

.trade_card.trade_card_spot .spot-list-search {
    /* height: 48px; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 8px;
    margin: 11px 0;
}


.trade_card_spot .trade_tabs {
    padding: 0 8px;
    border-bottom: 1px solid #ffffff14;
}

.trade_card_spot ul.nav.custom-tabs.nav_order {
    justify-content: flex-start;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    margin-top: 5px;
}


.trade_card_spot ul.nav.custom-tabs.nav_order li {
    display: inline-block;
}
.trade_card_spot ul.nav.custom-tabs.nav_order li a {
    padding-right: 12px;
    position: relative;
}
.trade_card_spot ul.nav.custom-tabs.nav_order li a:after{
    position: absolute;
    content: " ";
    width: 16px;
    height: 2px;
    border-radius: 2px;
    background-color: #ffdc88;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) scaleX(.5);
    opacity: 0;
    transition: all .16s ease-in;
}
.trade_card_spot ul.nav.custom-tabs.nav_order li a.active {
    background: transparent !important;
    color: #ffdc88 !important;
}
.trade_card_spot ul.nav.custom-tabs.nav_order li a.active:after {
    opacity: 1;
    transform: translateX(-50%) scaleX(1);
}


.markets_row__xELC_ {
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 20px;
    padding-inline-end: 30px;
    position: relative;
    height: 47px;
}
.markets_row__xELC_ .markets_col1__kEdFH {
    width: 50%;
    -webkit-padding-start: 15px;
    padding-inline-start: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-inline-start: 12px;
}
.markets_row__xELC_ .markets_col2__RMpcV {
    width: 26%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.markets_row__xELC_ .markets_col3__LCAVs {
    width: 24%;
    text-align: end;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.markets_favIcon__w4ieP {
    cursor: pointer; 
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 6px;
}



.markets_rowItem__RPIFi .markets_symbol__gj7q4 {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 1.3;
}
.markets_rowItem__RPIFi.markets_rowActive__NlFL8 .markets_strong__s_Hxk {
    font-weight: 700;
    font-size: 13px;
}
.markets_secondLine__vuaeq {
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    opacity: 0.6;
    line-height: 1.3;
}
.markets_row__xELC_ .markets_col3__LCAVs { 
    font-size: 12px;
    line-height: 1.3;
}


.price_card_head.markets_row__xELC__head  > div:first-child { 
    width: calc(50% - 30px);
    width: 50%;
    text-align: left;
}
.price_card_head.markets_row__xELC__head > div:nth-child(2) { 
  width: 26%; 
  text-align: left;
}
.price_card_head.markets_row__xELC__head > div:last-child { 
  width:24%; 
  text-align: right;
  padding-right: 0;
}
.price_card_head.markets_row__xELC__head{
    padding-right: 30px;
}


.treade_card_header.buy_sell_cards a {
    background: transparent;
    position: relative;
    font-size: 15px !important;
    padding: 10px 15px !important;
}
ul.nav.custom-tabs.order_his_tabs li a.active {
    background: transparent !important;
    color: #ffdc88  !important;
}

.theme-light ul.nav.custom-tabs.order_his_tabs li a.active { 
    color: #000000  !important;
}

ul.nav.custom-tabs.order_his_tabs li a:before {
    position: absolute;
    content: " ";
    width: 16px;
    height: 2px;
    border-radius: 2px;
    background-color: #ffdc88;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) scaleX(.5);
    opacity: 0;
    transition: all .16s ease-in;
}
ul.nav.custom-tabs.order_his_tabs li a.active:before { 
    transform: translateX(-50%) scaleX(1);
    opacity: 1; 
} 

.theme-light .trade-wrapper table.table {
    background: transparent !important;
}

.trade-wrapper table.table.table_home thead tr th {
    height: 40px;
    padding: 0 15px;
    font-size: 13px;
    font-weight: 400;
    line-height: 40px !important;
}
.trade-wrapper table.table.table_home tbody tr td {
    height: 38px;
    padding: 0 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 38px !important;
    color: #ffffffa6;
    border-bottom: 1px solid #ffffff08;
}
.theme-light .trade-wrapper table.table.table_home tbody tr td { 
    color: #060606a6; 
}
.trade-wrapper table.table.table_home tbody tr td a.btn-link {
    padding: 0 8px;
    text-decoration: none;
    opacity: 1;
    color: #ffffff82;
    font-size: 15px;
    transition: 0.1s;
}
.theme-light .trade-wrapper table.table.table_home tbody tr td a.btn-link {
    color: #000000ab;
}

.trade-wrapper table.table.table_home tbody tr td a.btn-link:hover {
    color: #ff4761; 
}

.trade-wrapper table.table.table_home thead tr th .spot_limit.num-div {
    margin: 0 0;
}



/* responsive */
@media (max-width: 767px){


    .headline_left__lBBPY_leftmain.d-flex.align-items-center {
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: flex-start;
    }

    .headline_left__lBBPY_leftmain  .headline_symbolName__KfmIZ {
        margin-bottom: 9px;
    }
    .headline_left__lBBPY_rightmain .headline_extendInfoWrapper__dooIS {
        flex-direction: column;
        align-items: flex-start !important;
    }
    .headline_left__lBBPY_rightmain .headline_extendInfoWrapper__dooIS .headline_leftItem__7BFYq {
        display: flex;
        width: 100%;
    }

    .headline_left__lBBPY_rightmain .headline_extendInfoWrapper__dooIS .headline_leftItem__7BFYq .headline_withBorder__a6ZD2 {
        flex: 1 1;
        text-align: right;
        margin-inline-end: 15px;
    }
    .headline_left__lBBPY_rightmain .headline_extendInfoWrapper__dooIS .headline_leftItem__7BFYq .headline_title__x1csO{
        flex: 1 1;
        text-align: right;
        -webkit-margin-end: 0;
        margin-inline-end: 0;
    }
    .headline_latestPrice__AYXu0 .headline_title__x1csO {
        font-size: 18px;
    }

    span.headline_change__tGyxo {
        font-size: 16px;
    }
    .scroll-container {
        overflow-y: auto;
        max-height: 175px;
        height: auto;
    }
    .exchange_chartContent__Ijukg {
        height: auto;
        overflow-y: auto;
    }

    .information_bright__F_RTd {
        opacity: 1 !important;
        margin-left: 6px;
    }
    .information_left__vgFSY, .information_right__kqyPI {
        width: 41%;
    }

    .trade-wrapper .btn { 
        height: auto !important;
        min-height: unset !important;
        margin-top: 1px;
    }
    .btn-group.btn-group-mini { 
        margin-bottom: 3px;
    }

    .btn-group.btn-group-mini * { 
        padding: 5px 0px; 
    }

    .col-12.col-sm-6.col-lg-6.col-xl-3.col-xxl-2.order-2, .col-12.col-sm-6.col-lg-6.col-xl-3.col-xxl-2.order-3.order-xl-3 {
        margin-top: 0;
    }
    .price_card_body.price_card_body_full, .scroll_y {
        height: 240px; 
    }
    .scroll_y_full {
        /* height: 480px; */
    }
    .scroll_y_coins {
        height: 339px;
    }

    footer.footer-wrapper {
        margin-top: 0;
    }


}




  .loading-wave {
    width: 300px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  
  .loading-bar {
    width: 3px;
    height: 5px;
    margin: 0 5px;
    background-color: #ffd900ce;
    border-radius: 5px;
    animation: loading-wave-animation 1s ease-in-out infinite;
  }
  
  .loading-bar:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  .loading-bar:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  .loading-bar:nth-child(4) {
    animation-delay: 0.3s;
  }
  
  @keyframes loading-wave-animation {
    0% {
      height: 5px;
    }
  
    50% {
      height: 25px;
    }
  
    100% {
      height: 5px;
    }
  }
  