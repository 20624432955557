
main.error_page {
    padding: 9% 0;
    width: 100%;
}

.error_page h1 {
    font-size: 7.5em;
    margin: 15px 0px;
    font-weight: bold;
    color: #FBBB57 !important;
    line-height: 1;
}

.error_page h2 {
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: .5rem;
}

.error_page p {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
    color: #959595 !important;
}

.error_page .btn,
.go-home {
    z-index: 1;
    overflow: hidden;
    position: relative;
    font-size: 1.1em;
    letter-spacing: 2px;
    transition: 0.2s ease;
    margin: 5px 0px;
    background: #FBBB57;
    color: black !important;
    font-weight: 600 !important;
    padding: 15px 25px;
    border-radius: 50px;
    box-shadow: 0px 4px 19px rgb(243 216 30 / 36%);
    letter-spacing: normal;
    font-weight: 500;
    min-width: 150px;
    border: 0;
    display: inline-block;
}


.reality_case img {
    position: relative;
}

@keyframes MoveUpDowns {
    0%, 100% {
        bottom: -20px;
    }
    50% {
        bottom: 20px;
    }
}

@-webkit-keyframes MoveUpDowns {
    0%, 100% {
        bottom: -20px;
    }
    50% {
        bottom: 20px;
    }
}

.error_page .move_on {
    animation: MoveUpDowns 4s linear infinite;
    -webkit-animation: MoveUpDowns 4s linear infinite;
    position: relative;
}

 
@media(max-width:767px){
    main.error_page,
        .thankyoupage {
            margin-top: 105px;
            padding: 50px 15px;
            text-align: center;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
        .error_page h1 {
            font-size: 5.5em;
            margin-top: 2rem;
        }
        .error_page h2 {
            font-size: 1.6rem;
        }
        .wrapper-1 h1 {
            font-size: 3.2em;
        }
        .wrapper-2 p {
            font-size: 15px;
            line-height: 1.6;
        }
    }