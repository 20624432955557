/* Change header background color */
.ant-table-wrapper .ant-table-thead >tr>th {
    background: rgb(37, 47, 48) ;
    color: #ababab;
}
.ant-table-wrapper .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover{
    background: rgb(37, 47, 48);
}
.ant-table-row-expand-icon-cell .ant-table-row-expand-icon, .ant-table-row-indent+.ant-table-row-expand-icon{
    background: none;
    color:  white;
}